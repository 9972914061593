@import "../mixins.scss";

.data-presentation {
  .data-presentation-scrollable {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .data-presentation-content {
    max-width: 1280px;
    margin: auto;
    position: relative;
    padding: 0 40px;
    padding-bottom: 10rem;
    .block-header {
      position: relative;
      z-index: 1;

      p {
        max-width: 480px;
      }
    }

    .data-presentation-title {
      margin-bottom: 32px;
    }

    .block-content {
      position: relative;
      z-index: 1;

      .presentation-items {
        display: flex;
        justify-content: space-between;
        min-width: 1200px;
        min-height: 180px;
      }


      .block-item {
        opacity: 0;
        position: relative;
        display: flex;
        text-align: center;
        border-radius: 16px;
        max-width: 210px;
        width: 100%;
        height: 110px;
        h3 {
          background: $dark-text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          margin-bottom: 1rem;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }

        &:nth-child(2) {
          margin-bottom: 28px;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:last-child {
          animation-duration: 5s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }

      .carousel-3d-container {
        height: 460px !important;
        padding: 20px 0;
        .carousel-3d-slider {
          .carousel-3d-slide {
            display: flex;
            background: no-repeat;
            border: none;
            box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
            .presentation-slider-image {
              background-repeat: no-repeat;
              background-size: cover;
              background-position: top;
              width: 100%;
              height: 100%;
            }
          }
        }
        .carousel-3d-controls {
          .prev,
          .next {
            border-radius: 8px;
            overflow: hidden;
            transition: 0.3s;
            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              backdrop-filter: blur(3px);
              filter: blur(3px);
            }
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("#{$VUE_APP_BASE}/assets/icons/slider-arrow.svg") no-repeat center;
            }
            span {
              display: none;
            }

            &:hover {

            }
          }

          .prev {
            left: 0;
          }

          .next {
            right: 0;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .data-presentation-content-header {
    margin-bottom: 96px;
  }

  &.animated {
    .data-presentation-content {
      .block-content {
        .block-item {
          &:first-child {
            animation-name: dataPresentation1;
          }

          &:nth-child(2) {
            animation-name: dataPresentation2;
          }

          &:nth-child(3) {
            animation-name: dataPresentation3;
          }

          &:nth-child(4) {
            animation-name: dataPresentation4;
          }

          &:last-child {
            animation-name: dataPresentation5;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px){
    .data-presentation-content {
      padding: 0 40px;
      padding-bottom: 70px;
      .block-content {
        //background-position: -15% 140%;
        .presentation-items {
          //justify-content: flex-end;
        }
      }
    }
  }

  @include below-desktop {
    .data-presentation-content {
      .block-content {
        .presentation-items {
          justify-content: flex-start;
          //flex-wrap: wrap;
          gap: 40px;
          .block-item {
            //margin-right: 2rem;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
  @include tablet-wide {
    .data-presentation-title {
      margin-bottom: 32px;
    }
    .data-presentation-scrollable {
      width: calc(100% + 80px);
      height: 110px;
      padding-left: 40px;
      position: relative;
      left: -40px;
    }
  }
  @include tablet {
    .data-presentation-content {
      .block-content {
        .presentation-items {
          justify-content: space-around;
          .block-item {
            max-width: 250px;
            margin-right: 0;
          }
        }
      }
    }
  }

  @include mobile {
    .data-presentation-content {
      padding: 2rem 20px;
      .block-header {
        margin-bottom: 3rem;
      }

      .data-presentation-title {
        margin-bottom: 20px;
      }

      .block-content {
        .presentation-items {
          display: block;
          min-width: unset;
          .block-item {
            max-width: unset;
            height: unset;
            margin-bottom: 48px;
            h3 {
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 12px;
            }
            br {
              display: none;
            }
            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }

        .presentation-slider {
          .carousel-3d-container {
            margin: 0;
            height: 300px !important;
            .carousel-3d-controls {
              top: calc(50% - 16px);
            }
          }
        }
      }
    }

    .data-presentation-scrollable {
      height: unset;
      padding-right: 40px;
    }

    .data-presentation-content-header {
      margin-bottom: 60px;
    }
  }
}