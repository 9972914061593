@import "../colors.scss";
@import "../mixins.scss";

.video-block {
  display: flex;
  justify-content: center;
  margin-top: 72px;
  .video-block-wrapper {
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    background-image: url("#{$VUE_APP_BASE}/assets/images/landing/video_bg.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    position: relative;
    height: 360px;
    overflow: hidden;
  }
  video {
    object-fit: cover;
    position: relative;
    width: 640px;
    max-width: 100%;
    height: 360px;
  }

  .playpause {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    overflow: hidden;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      .icon {
        width: 30px;
        height: 30px;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(3px);
    }
    .icon {
      transition: 0.3s;
      position: relative;
      z-index: 10;
      width: 26px;
      height: 26px;
    }

    &.hidden {
      top: -100%;
      left: -100%;
      opacity: 0;
    }
  }

  .geoeffect-controls {
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    position: absolute;
    min-height: 62px;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0.5rem 1rem;
    &.hidden {
      padding: 0;
      height: 0;
      min-height: 1px;
      opacity: 0;
    }

    .geoeffect-controls-wrap {
      display: flex;
      align-items: center;
    }

    .controls-playpause {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      margin-right: 1rem;
      .icon {
        width: 24px;
        height: 24px;
        &:before {
          width: 24px;
          height: 24px;
        }
      }
    }

    .geoeffect-progress {
      cursor: pointer;
      width: 100%;
      height: 4px;
      border-radius: 8px;
      background: rgba(188, 195, 208, 0.50);
      .geoeffect-current {
        position: relative;
        border-radius: 8px;
        background: $white-text;
        height: 4px;
        &:before {
          position: absolute;
          content: '';
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: $white-text;
          margin-top: -10px;
          right: -12px;
        }
      }
    }

    .geoeffect-time {
      .geoeffect-currenttime {
        transform: translate(-40px, 20px);
        color: $light-white-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

@include mobile {
  .video-block {
    margin-top: 72px;
  }
}

@include mobile {
  .video-block {
    margin-top: 2.5rem;

    .video-block-wrapper {
      max-height: 295px;
      height: 100vh;
    }

    video {
      width: 100%;
      max-width: 520px;
      max-height: 300px;
      object-fit: contain;
      height: auto;
    }
  }
}

@media screen and (max-width: 570px){
  .video-block {
    .video-block-wrapper {
      max-height: 240px;
    }

    video {
      max-height: 240px;
    }
  }
}

@media screen and (max-width: 480px){
  .video-block {
    .video-block-wrapper {
      max-height: 220px;
    }

    video {
      max-height: 220px;
    }
  }
}

@media screen and (max-width: 440px){
  .video-block {
    .video-block-wrapper {
      max-height: 195px;
    }

    video {
      max-height: 195px;
    }
  }
}

@media screen and (max-width: 400px){
  .video-block {
    .video-block-wrapper {
      max-height: 180px;
    }

    video {
      max-height: 180px;
    }
  }
}

@media screen and (max-width: 370px){
  .video-block {
    .video-block-wrapper {
      max-height: 170px;
    }

    video {
      max-height: 170px;
    }
  }
}


@media screen and (max-width: 350px){
  .video-block {
    .video-block-wrapper {
      max-height: 150px;
    }

    video {
      max-height: 150px;
    }
  }
}